import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Navigation() {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleLangChange = (evt) => {
        const lang = evt.target.value;

        if (lang === 'English') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('fr');
        }
    };

    const linkBlock = <div className={`flex flex-col md:flex-row md:space-x-3 mt-2 md:mt-0 md:ml-5 md:items-center ${isOpen ? 'block' : 'hidden'} md:block gap-2`}>
        <Link to="produits" className="nav-link" onClick={() => setIsOpen(false)}>{t('produit')}</Link>
        <Link to="scotch" className="nav-link" onClick={() => setIsOpen(false)}>Scotch</Link>
        <Link to="exemption" className="nav-link" onClick={() => setIsOpen(false)}>Exemption</Link>
        <Link to="heures-ouverture" className="nav-link" onClick={() => setIsOpen(false)}>{t('hours')}</Link>
        <Link to="contact" className="nav-link" onClick={() => setIsOpen(false)}>Contact</Link>
        <div className='w-24 md:absolute md:right-5 md:top-0 md:m-5'>
            <button className="p-2 rounded-md w-full bg-zinc-800 text-white" onClick={handleLangChange} value={t('langue')}>{t('langue')}</button>
        </div>
    </div>;

    return (
        <nav class="flex items-center justify-between flex-wrap p-6">
            <Link to="/" class="font-bold text-xl tracking-tight text-black" onClick={() => setIsOpen(false)}>Duty Free Lacolle</Link>
            <div class="block md:hidden">
                <button class="flex items-center px-3 py-2 border rounded hover:text-slate-900 hover:border-slate-900" onClick={() => setIsOpen(!isOpen)}>
                    <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>
            <div class="w-full block flex-grow md:flex lg:items-center md:w-auto">
                {linkBlock}
            </div>
        </nav>
    );
}

export default Navigation;
