import React from 'react';
import Produit from '../components/Produit';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * Home page.
 * @return {jsx} The home page.
 */
function Home() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4 flex flex-col justify-center items-center'>
      <PageTitle title={t('welcome')} subtext={t('welcomeMessage')} />
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 justify-center lg:w-3/4'>
        <Produit code="11584013" nom="Crown Royale" prix="23.95" description="1140ml" prixSAQ="41.25" />
        <Produit code="11530841" nom="Bombay Sapphire" prix="20.95" description="1000ml" prixSAQ="43.25" />
        <Produit code="11724979" nom="Coureur des bois" prix="26.95" description="750ml" prixSAQ="36.75" />
      </div>
    </div>
  );
}

export default Home;
