import React from 'react';
import { useQuery } from 'react-query';
import HoursServices from '../service/HoursService';
import { useTranslation } from 'react-i18next';

/**
 * Hours block for each duty free.
 * @return {JSX} The component.
 */
function HoursBlock({ name, dutyFreeId }) {
    const { t } = useTranslation();
    const query = useQuery(`query-for-${dutyFreeId}`, () => HoursServices.getHoursForId(dutyFreeId), {
        retry: 1,
    });

    if (query.isLoading) return 'Loading...';

    if (query.isError) return 'An error has occurred.';

    return (
        <div className='flex flex-col justify-center items-center bg-white rounded-lg'>
            <h2 className='my-3'>{name} Route {dutyFreeId}</h2>
            <table className='w-full bg-white max-w-[500px]'>
                <tbody class="divide-y divide-gray-200 border text-center">
                    <tr className='border'>
                        <td class="px-4 py-2 border">{t('lundi')}</td>
                        <td class="px-4 py-2 border">{query.data.mondayOpen ? query.data.mondayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.mondayClose ? query.data.mondayClose : t('fermer')}</td>
                    </tr>
                    <tr class="bg-gray-200 border">
                        <td class="px-4 py-2 border">{t('mardi')}</td>
                        <td class="px-4 py-2 border">{query.data.tuesdayOpen ? query.data.tuesdayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.tuesdayClose ? query.data.tuesdayClose : t('fermer')}</td>
                    </tr>
                    <tr className='border'>
                        <td class="px-4 py-2 border">{t('mercredi')}</td>
                        <td class="px-4 py-2 border">{query.data.wednesdayOpen ? query.data.wednesdayOpen : t('fermer')}</td>
                        <td class="px-4 py-2">{query.data.wednesdayClose ? query.data.wednesdayClose : t('fermer')}</td>
                    </tr>
                    <tr class="bg-gray-200 border">
                        <td class="px-4 py-2 border">{t('jeudi')}</td>
                        <td class="px-4 py-2 border">{query.data.thursdayOpen ? query.data.thursdayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.thursdayClose ? query.data.thursdayClose : t('fermer')}</td>
                    </tr>
                    <tr className='border'>
                        <td class="px-4 py-2 border">{t('vendredi')}</td>
                        <td class="px-4 py-2 border">{query.data.fridayOpen ? query.data.fridayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.fridayClose ? query.data.fridayClose : t('fermer')}</td>
                    </tr>
                    <tr class="bg-gray-200 border">
                        <td class="px-4 py-2 border">{t('samedi')}</td>
                        <td class="px-4 py-2 border">{query.data.saturdayOpen ? query.data.saturdayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.saturdayClose ? query.data.saturdayClose : t('fermer')}</td>
                    </tr>
                    <tr className='border'>
                        <td class="px-4 py-2 border">{t('dimanche')}</td>
                        <td class="px-4 py-2 border">{query.data.sundayOpen ? query.data.sundayOpen : t('fermer')}</td>
                        <td class="px-4 py-2 border">{query.data.sundayClose ? query.data.sundayClose : t('fermer')}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    );
}

export default HoursBlock;
