const horaire221 = {
    'mondayOpen': '',
    'mondayClose': '',
    'tuesdayOpen': '',
    'tuesdayClose': '',
    'wednesdayOpen': '',
    'wednesdayClose': '',
    'thursdayOpen': '9:00',
    'thursdayClose': '5:00',
    'fridayOpen': '9:00',
    'fridayClose': '5:00',
    'saturdayOpen': '9:00',
    'saturdayClose': '4:00',
    'sundayOpen': '9:00',
    'sundayClose': '4:00',
};

export default horaire221;
