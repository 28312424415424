import React from 'react';

/**
 * Product card.
 * @return {jsx} The component.
 */
function Produit({ code, nom, description, prix, prixSAQ }) {
  return (
    <div className='w-full flex flex-col border rounded-lg overflow-hidden bg-slate-50'>
      <img src={`https://www.dutyfreelacolle.com/img/produits/${code}.jpeg`} alt={`${nom}`} />
      <div className='p-2'>
        <p className='inline-block font-bold'>{nom}</p>
        <p>{description}</p>
        <div className='flex flex-wrap items-start mt-3'>
          <p className='inline-block bg-black text-white rounded-lg p-2 border'>Duty Free: {prix}$</p>
          <p className='inline-block bg-red-600 text-white rounded-lg p-2 border'>SAQ: {prixSAQ}$</p>
        </div>
      </div>
    </div>
  );
}

export default Produit;
