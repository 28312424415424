import React from 'react';
import Produit from '../components/Produit';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * The scotch page.
 * @return {jsx} The scotch page.
 */
function Scotch() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4 flex flex-col justify-center items-center'>
      <PageTitle title={t('scotch')} subtext={t('scotchMessage')} />
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 justify-center lg:w-3/4'>
        <Produit code="530352" nom="Glenfiddich 18YO" prix="114.95" description="700ml" prixSAQ="185.25" />
        <Produit code="14156014" nom="Johnnie Walker Black" prix="37.95" description="1000ml" prixSAQ="77.75" />
        <Produit code="13958058" nom="Glenfiddich 21YO" prix="214.95" description="700ml" prixSAQ="421.00" />
        <Produit code="14482485" nom="Glenfiddich 23YO" prix="349.95" description="700ml" prixSAQ="600.20" />
        <Produit code="13008626" nom="The Balvenie 12YO" prix="89.95" description="1000ml" prixSAQ="141.25" />
        <Produit code="11740223" nom="Chivas 12YO" prix="37.95" description="1000ml" prixSAQ="78.33" />
      </div>
    </div>
  );
}

export default Scotch;
